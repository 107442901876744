export var isDevelopment = function () {
    return typeof window === "undefined" ||
        process.env.NODE_ENV === "development" ||
        window.location.host === "localhost:3000" ||
        window.location.host === "arcade-master.arcadexr.net";
};
export var isMasterDevelopment = function () {
    return typeof window === "undefined" ||
        process.env.NODE_ENV === "development" ||
        window.location.host === "arcade-master.arcadexr.net";
};
export var isStaging = function () {
    return typeof window !== "undefined" && window.location.host === "arcade-staging1.arcadexr.net";
};
export var isLive = function () {
    return typeof window !== "undefined" && window.location.host === "arcade.online";
};
export var getEnvPath = function () {
    if (isLive())
        return "https://arcade.online";
    if (isDevelopment()) {
        if (window.location.host === "localhost:3000")
            return "https://localhost:8443";
        return "https://arcade-master.arcadexr.net";
    }
    if (isStaging())
        return "https://arcade-staging1.arcadexr.net";
};
